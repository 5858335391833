@import '../../index.scss';

.playscreen_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-image: url($image_pre + 'Game_Selection_Background1.png');
  background-size: 100% 100%;
}

.playscreen_content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.playscreen_buttoncontainer {
  display: flex;
  margin: 0 3vmin;
  // filter: hue-rotate(30deg);
}

.playscreen_playbutton {
  margin: 0 clamp(5px, 2.5vmin, 20px);
  background-image: url($image_pre + 'Category_Selection_Play_Button_Up2x.png');
  background-size: 100% 100%;
  height: clamp(40px, 10vmin, 100px);
  width: clamp(105px, 25vmin, 250px);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: zoom 0.5s ease-out 0s 1 normal forwards;
  // filter: hue-rotate(128deg);
}

.playscreen_playbutton:nth-child(1) {
  filter: hue-rotate(128deg);
}

.playscreen_playbutton:nth-child(2) {
  filter: hue-rotate(hue(#ebeb15)) brightness(1.6);
}

._disabled {
  pointer-events: none;
  filter: grayscale(100%) !important;
  opacity: 0.6;
}

.playscreen_playtext {
  color: white;
  font-weight: 400;
  font-size: clamp(24px, 5vmin, 60px);
  filter: drop-shadow(3px 3px 0px black);
}

@media (max-width: 700px) {
  .playscreen_buttoncontainer {
    flex-direction: column;
  }

  .playscreen_playbutton {
    margin: clamp(5px, 2.5vmin, 20px) 0;
    height: clamp(80px, 20vmin, 200px);
    width: clamp(210px, 50vmin, 500px);
  }

  .playscreen_playtext {
    font-size: clamp(48px, 12vmin, 120px);
  }
}

.playscreen_descriptionbox {
  padding: clamp(10px, 8vmin, 30px) clamp(5px, 3vmin, 20px);
  margin-top: clamp(12px, 4vmin, 80px);
  display: flex;
  justify-content: center;
  width: clamp(280px, 80vmin, 1000px);
  background-color: rgba(0, 0, 0, 0.8);
  border-top-left-radius: 30% 49%;
  border-bottom-left-radius: 30% 49%;
  border-top-right-radius: 30% 49%;
  border-bottom-right-radius: 30% 49%;
  border: 2px rgba(255, 255, 255, 0.8) solid;
  animation: flip 0.75s linear 0s 1 normal forwards;
}

.playscreen_descriptiontext {
  color: white;
  font-size: clamp(15px, 4vmin, 24px);
  margin: 0;
  text-align: center;
}

@keyframes flip {
  0% {
    opacity: 0.5;
    transform: rotateY(180deg) scale(0);
  }

  100% {
    transform: rotateY(360deg)scale(1);
    opacity: 1;
  }
}

@keyframes zoom {
  0% {
    transform: translateY(-100vh);
  }

  100% {
    transform: translateY(0vh);
  }
}

.playscreen_gametypebadgecontainer {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-image: url($image_pre + 'Game_Type_Header2x.png');
  background-size: 100% 100%;
  width: 110px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.playscreen_gametypebadge {
  width: 100px;
  height: 100px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}