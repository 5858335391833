.va_fullscreen {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

$imageDim: clamp(50px, 20vmin, 350px);
.va_imageclickcontainer {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.va_container {
  width: 35vw;
  display: flex;
}

.va_imageclick_top {
  @extend .va_container;
  margin-bottom: clamp(50px, 10vmin, 60px);
}

.va_imageclick_bottom {
  @extend .va_container;
  margin-top: clamp(50px, 10vmin, 60px);
}

.va_imageclick {
  border-radius: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  // position: absolute;
  border: solid 3px black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 95% 95%;
  width: $imageDim;
  height: $imageDim;
}


// $centerDim: clamp(100px, 35vmin, 350px);

.va_centercontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border: solid 3px black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 95% 95%;
  // width: $centerDim;
  // height: $centerDim;
  // border-radius: $centerDim;
  background-color: rgba(22,174,239,1);
}

.va_centertext {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  // font-size: clamp(8px, 4vmin, 40px);
  font-family: 'BadaBoom';
  filter: drop-shadow(2px 2px 0px black);
  letter-spacing: clamp(2px, 0.15vmin, 5px);
  text-align: center;
}