@import '../../index.scss';

.welcome_container {
  position: absolute;
  // z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-image: url($image_pre + 'welcome_background.png');
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcome_seasonstree {
  position: relative;
  background-image: url($image_pre + 'seasonscircle.png');
  background-size: 100% 100%;
  height: clamp(300px, 75vmin, 600px);
  width: clamp(300px, 75vmin, 600px);
  border-radius: clamp(300px, 75vmin, 600px);
  height: clamp(300px, 48vmin, 600px);
  width: clamp(300px, 48vmin, 600px);
  border-radius: clamp(300px, 48vmin, 600px);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-140vmin);
  animation-name: slide-in;
  animation-duration: 0.75s;
  animation-delay: .05s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  filter: drop-shadow(0px 0px 20px white)
}

@keyframes slide-in {
  0% {
    transform: translateX(-150vmin);
  }

  100% {
    transform: translateX(0vmin);
  }
}

.welcome_modal {
  position: absolute;
  background-image: url($image_pre + 'Modal_Message_Display1.png');
  background-size: 100% 100%;
  height: clamp(25px, 25vmin, 200px);
  width: clamp(80px, 80vmin, 640px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.welcome_guidecharacter {
  position: absolute;
  top: 0;
  left: 0;
  background-image: url($image_pre + 'Guide_Character_Badge.png');
  background-size: 100% 100%;
  height: clamp(50px, 13vmin, 125px);
  width: clamp(50px, 13vmin, 125px);
  filter: drop-shadow(7px 10px 7px rgba(0, 0, 0, 0.5));
}

.welcome_modaltext {
  line-height: 0em;
  position: relative;
  left: clamp(-48px, -48vmin, -384px);;
}