@import '../../../index.scss';
@import '../index.scss';

.EM-DS-container {
  @extend .EM-container;
  background-image: url($image_pre + 'Category_Selection_BG.png');
  background-size: 100% 100%;
}

.EM-DS-categoryText {
  margin: 3px 8px;
  background-image: url($image_pre + 'Category_Selection_Play_Button_Up2x.png');
  background-size: 100% 100%;
  color: white;
  font-weight: bold;
  padding: 10px 20px;
  font-family: AboriginalSans;
  font-size: clamp(12px, 4vmin, 20px);
  filter: drop-shadow(1px 1px 1px black);
  margin-top: 1vmin;
}

.EM-DS-categoryContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.EM-DS-fullscreen {
  background-image: url($image_pre + 'Game_Selection_Background1.png');
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.EM-DS-overlayScreen {
  position: fixed;
  z-index: 9999;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, .7);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  line-height: 1.3em;
}

.EM-DS-popupWindow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px;
  border: 4px solid black;
  height: 26.67vw;
  width: 66.67vw;
  min-height: 260px;
  min-width: 350px;
}

.EM-DS-buttonContainer {
  display: flex;
  // justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  position: relative;
  top: -10vmin;
}

.EM-DS-button {
  background-image: url($image_pre + 'Category_Selection_Play_Button_Up.png');
  background-size: 100% 100%;
  height: clamp(30px, 20vmin, 120px);
  width: clamp(120px, 80vmin, 480px);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 5vmin;

}
.EM-DS-textcontainer {
  filter: drop-shadow(1px 1px 0 black);
}
.EM-DS-languagetext {
  font-family: AboriginalSans;
  font-size: clamp(18px, 8vmin, 60px);
  font-weight: 900;
  // filter: drop-shadow(-1px -1px 0px black);
  color: rgba(0,0,0,0.5);
  line-height: 1;
  padding-top: 1.5vmin;
  // background-image: url($image_pre + 'Category_Selection_Play_Button_Up.png');
  // background-size: 120% 120%;
  // background-position: center;
  // background-clip: text;
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  position: relative;
  // clip-path: rect(10%, 10px, 20px, 0);
  letter-spacing: 4px;
}
.EM-DS-englishtext {
  font-family: BadaBoom;
  font-size: clamp(18px, 8vmin, 60px);
  // font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
  line-height: 1;
  // padding-top: 1.5vmin;
  position: relative;
  letter-spacing: 6px;
}

.EM-DS-topBannerSub {
  font-size: clamp(10px, 1.5vmin, 20px);
  display: flex;
}

.EM-DS-topBanner {
  position: relative;
  top: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: min(5vh, 50px);
  width: 100vw;
  min-height: 50px;
  height: 10vh;
  max-height: 150px;
  background-color: rgba(255, 146, 32, .8);
  border-radius: 0% 0% 50% 50%;
  color: white;
  border: 2px solid black;
  line-height: 1em;
  font-size: clamp(16px, 2vmin, 40px);
}

.EM-DS-textContainer {
  line-height: 1.5em;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  height: 100%;
  width: 100%;
  font-size: 2.67vw;
  position: relative;
}
.EM-DS-headercontainer {
  background-image: url($image_pre + 'Game_Type_Stars_Base2x.png');
  background-size: 100% 100%;
  padding: clamp(15px, 1vmin, 25px) clamp(25px, 7vmin, 50px);
  position: absolute;
  top: clamp(-80px, -8vmin, -40px);
}
.EM-DS-headertext {
  font-size: clamp(35px, 6vmin, 75px);
  letter-spacing: clamp(1px, 0.5vmin, 5px);
  color: white;
  position: relative;
  top: -0.7vmin;
  left: 1vmin;
}

.EM-DS-difficultyDisplay {
  background-image: url($image_pre + 'Game_Play_Success_Display_Stat_Back2x.png');
  background-size: 100% 100%;
  padding: clamp(15px, 1vmin, 25px) clamp(25px, 7vmin, 50px);
  color: white;
  letter-spacing: clamp(1px, 0.5vmin, 5px);
  font-size: clamp(22px, 2.67vmin, 38px);
}

.EM-DS-categoryContainerFull {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 10px;
  font-size: 1.78vw;
}

.EM-DS-menuButton {
  min-height: 40px;
  min-width: 100px;
  height: 6.12vw;
  width: 14.29vw;
  max-height: 100px;
  max-width: 280px;
  background-color: rgba(49, 181, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  color: white;
  font-weight: bold;
  margin: 20px;
  box-shadow: 2px 2px 2px black;
  font-size: 1.78vw;
}

.EM-DS-flex {
  display: flex;
  justify-content: center;
}

.EM-DS-bottomdisplay {
  // background-color: rgba(255, 0, 0, .7);
  width: 100%;
  // height: 14vmin;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 9999999999;
}

.EM-DS-bottomheader {
  background-image: url($image_pre + 'Game_Type_Stars_Base2x.png');
  background-size: 100% 100%;
  width: clamp(130px, 30vmin, 600px);
  height: clamp(30px, 10vmin, 70px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 3vmin;
}

.EM-DS-bottomheader-text {
  font-size: clamp(18px, 4vmin, 36px);
  font-family: BadaBoom;
  letter-spacing: 2px;
  color: white;
  filter: drop-shadow(1px 1px 1px black);
}