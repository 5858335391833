.CG-displayDOM {
  background-color: rgba(0, 0, 0, .3);
  padding: min(3vw, 20px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.CG-numberDis {
  height: 40px;
  padding: 0px 1.25vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: min(5vw, 5vh);
  line-height: 1.3;
}

.CG-symbolDis {
  height: 40px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 30px;
}

.CG-placemat {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.CG-DD-outerContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 25vh;
}

.CG-DD-innerContainer {
  width: 90vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.CG-plusMinusContainer {
  position: absolute;
  top: 40vh;
  left: 50vw;
  z-index: 99999;
  height: 2px;
  width: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CG-BSB-stat {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.CG-fullScreen {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, .2);
  position: absolute;
  top: 0px;
  left: 0px;
}

.CG-card-card {
  height: 50px;
  width: 50px;
  border-radius: 10px;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CG-card-cardContents {
  font-size: 35px;
  color: black;
}

.CG-card-emptyCard {
  height: 50px;
  width: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}