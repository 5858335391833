@import '../../index.scss';

.topcontainer {
  width: clamp(250px, 60vw, 500px);
  background-color: rgba(49, 181, 255, 1);
  border-radius: 10px;
  box-shadow: 2px 2px 3px black;
  display: flex;
  // justify-content: space-evenly;
  align-items: center;
  font-size: 35px;
  color: white;
  font-weight: bold;
  font-family: Aboriginal Sans;
  padding: 10px;
  text-shadow: 1px 1px 2px black;
}
.counting_centercontainer {
  position: absolute;
  top: 20px;
}
@media screen and (max-width: 630px) {
  .counting_centercontainer {
    top: calc(clamp(0px, 3vmin, 30px) - 30px);
  }
}
.counting_topcontainertext {
  flex: 1;
  line-height: 1em;
  font-size: clamp(18px, 2vmin, 25px);
  text-align: center;
}
.counting_checkbutton {
  height: clamp(20px, 8vmin, 40px);
  width: clamp(50px, 15vmin, 100px);
  font-size: clamp(15px, 5vmin, 28px);
  background-color: green;
  border-radius: 10px;
  box-shadow: 2px 2px 3px black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  font-family: Aboriginal Sans;
  margin-left: 10px;
  // padding: 10px 25px;
  line-height: 35px;
}

.counting_fullscreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}


.counting_notclicked {
  position: absolute;
  background-color: rgba(202, 176, 133, 1);
  border-radius: 10px;
  border: 3px solid black;display: flex;
  justify-content: center;
  align-items: center;
}
.counting_clicked {
  @extend .counting_notclicked;
  background-color: rgba(150, 200, 255, 1);
}
.counting_item {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
