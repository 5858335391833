// $image_pre: 'https://storage.googleapis.com/redlake-media/Image/';
$image_pre: 'https://storage.googleapis.com/salishmedia/Image/';
// $image_pre: 'https://storage.googleapis.com/fort-belknap-media/Image/';
// $image_pre: 'https://storage.googleapis.com/pokagon-media/Image/';

@font-face {
  font-family: BadaBoom;
  src: url('../public/fonts/BADABB__.TTF');
}
@font-face {
  font-family: AboriginalSans;
  src: url('../public/fonts/AboriginalSansREGULAR.ttf');
}

html,
body {
  cursor: url($image_pre + 'mouseDefault96.png'), url($image_pre + 'mouseDefault32.png'), auto;
}

.hovercursor {
  cursor: url($image_pre + 'mouseHover96.png') 20 7, url($image_pre + 'mouseHover32.png') 10 3, auto;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: clamp(20px, 6vmin, 45px);
  font-family: BadaBoom;
  font-weight: 300;
  letter-spacing: 0.075em;
}

h2 {
  font-size: clamp(20px, 4.5vmin, 35px);
  font-family: BadaBoom;
  font-weight: 300;
  letter-spacing: 0.07em;
}

p {
  font-family: BadaBoom;
  color: black;
}

a:link {
  text-decoration: none !important;
}

a:visited {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

a:active {
  text-decoration: none !important;
}