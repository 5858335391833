.solveItGame-yesClick {
  pointer-events: all;
}

.SIG-buttonOption>* {
  margin-bottom: 10px;
}

.SIG-cardDOMDisplay {
  // height: 25vh;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
}

.SIG-solveSpotContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.SIG-solveSpotButton {
  font-size: max(min(5vh, 5vw), 32px);
  color: black;
  height: max(min(6.25vh, 7.14vw), 40px);
  width: max(min(6.25vh, 7.14vw), 40px);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 7px solid black;
}

.SIG-content-container {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.SIG-buttonDataContainer {
  // background-color: blue;
}

.SIG-mainContentContainer {
  height: 100%;
  width: 80%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.SIG-BSB-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.SIG-BSB-textMargin {
  margin-right: 10px;
}

.SIGC-card {
  height: clamp(80px, 20vmin, 250px);
  width: clamp(60px, 14.5vmin, 180px);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thickBorder {
  border: black 7px solid;
}

.SIGC-cardContents {
  font-size: max(min(2vh, 1.79vw), 12px);
  color: black;
}
.round-banner-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}