@import '../../../index.scss';
@import '../index.scss';

.EM-GS-container {
  @extend .EM-container;
  background-image: url($image_pre + 'Category_Selection_BG.png');
  background-size: 100% 100%;
}

.EM-GS-screencontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 13vmin;
  background-image: url($image_pre + 'Category_Selection_BG.png');
  background-size: 100% 100%;
}

.EM-GS-buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: clamp(20px, 8vmin, 60px);
  margin-left: clamp(6px, 2.4vmin, 15px);
  margin-right: clamp(6px, 2.4vmin, 15px);
  background-image: url($image_pre + 'Catergory_Selection_Display1.png');
  background-size: 100% 100%;
  padding: 0 15px;
  padding-bottom: clamp(20px, 3.5vmin, 45px);
  position: relative;
}
.EM-GS-buttonTitle {
  color: white;
  padding-top: 8px;
  font-family: BadaBoom;
  filter: drop-shadow(1px 1px 2px black);
  letter-spacing: 2px;
  font-size: clamp(18px, 3vmin, 32px);
}
.EM-GS-badge {
  width: 90px;
  height: 90px;
  position: absolute;
  top: -15px;
  right: -32px;
  background-size: 100% 100%;
}
.EM-GS-buttonContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0,0,0,0.5);
  margin: 10px;
  margin-top: 18px;
  border-radius: 5px;
}

.EM-GS-Go-Button {
  background-image: url($image_pre + 'Category_Selection_Play_Button_Up2x.png');
  background-size: 100% 100%;
  width: clamp(90px, 39vmin, 180px);
  height: clamp(30px, 13vmin, 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
  position: absolute;
  bottom: clamp(-30px, -6vmin, -80px);
}

.EM-GS-scoreText {
  margin-right: 10px;
}

.EM-GS-difficultyDisplay {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 90%;
  margin: 5px 0px 5px 0px;
  padding: 5px;
  border-radius: 100px;
  color: white;
  text-align: center;
}