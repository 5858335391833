$topDisplayWidth: 210px;

.storyGame-headerText {
  font-family: "Aboriginal Sans";
}

.storyGame-pageNumber {
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 5px #000000;
}

.storyGame-outerBox {
  @extend .storyGame-pageNumber;
}

.storyGame-outerBox:hover {
  box-shadow: inset 2px 2px 5px #000000;
}

.storyGame-topBarContent {
  width: $topDisplayWidth / 3.3;
  background-color: rgba(49, 181, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  // border-radius: 10px;
  // flex: 1;
  padding: 3px 0px;
}

.storyGame-topBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
}

.storyGame-sentenceContainer {
  width: calc(100vw - (40px + $topDisplayWidth));
  background-color: rgba(49, 181, 255, 1);
  padding: 10px 0;
  position: relative;
  top: 10px;
  right: 20px + $topDisplayWidth;
}

.SG-vocab {
  margin-top: 5px;
  background-color: rgba(49, 181, 255, 1);
  height: clamp(38px, 7vmin, 45px);
  width: $topDisplayWidth;
}

.SG-fullScreen {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-size: 100% 100%;
}

.SG-topDisplay {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 10px;
  right: 10px;
}
.SG-topDisplayInner {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 10px;
  right: 10px;
  width: $topDisplayWidth;
}

.SG-sentence {
  text-shadow: 3px 3px 7px white;
  font-size: clamp(14px, 4vmin, 35px);
  margin: 0 10px;
}