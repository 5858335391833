.symbol-buttonStyle {
  color: black;
  background: linear-gradient(0deg, rgba(58, 109, 180, 1) 0%, rgba(69, 169, 252, 1) 100%);
  box-shadow: inset 0 1px #cfd8dc, 0 1px 2px rgba(0, 0, 0, 0.2);
  margin-bottom: 0;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
}

.symbol-emptySpot {
  background: linear-gradient(0deg, rgba(106, 137, 153, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

.symbol-buttonContents {
  font-size: max(min(5vh, 5vw), 32px);
  height: max(min(6.25vh, 7.14vw), 40px);
  width: max(min(6.25vh, 7.14vw), 40px);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}