@import '../../index.scss';

.redirect_container {
  background-image: url($image_pre + 'welcome_background.png');
  background-size: 102% 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.redirect_button {
  background-image: url($image_pre + 'Category_Selection_Play_Button_Up2x.png');
  background-size: 100% 100%;
  height: 100px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.redirect_buttontext {
  color: white;
  letter-spacing: 0.1em;
  font-size: 1.4em;

}