.center {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.centerContentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.all_pages_container {
  padding-top: 100px;
  padding-bottom: 95px;
}

.disabled_link {
  pointer-events: none;
}
.emptyCard {
  background: linear-gradient(0deg, rgba(106, 145, 131, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

.levelNumber {
  animation-duration: 1s;
  animation-name: growShrink;
}

@keyframes growShrink {
  0% {
    position: relative;
    top: 0px;
  } 50% {
    position: relative;
    top: -50px;
    font-size: 200px;
  } 100% {
    position: relative;
    top: 0px;
  }
}

.card-buttonStyle {
  background: linear-gradient(180deg, rgba(97, 214, 144, 1) 0%, rgba(70, 162, 128, 1) 100%);
  box-shadow: inset 0 1px #cfd8dc, 0 1px 2px rgba(0, 0, 0, 0.2);
  margin-bottom: 0;
  cursor: pointer;
  border: 1px solid transparent;
}
.canvas_container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plusminuscontainer {
  position: absolute;
  z-index: 999999;
  height: 2;
  width: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.aboriginalSans {
  font-family: "AboriginalSans";
}
.badaBoom {
  font-family: "BadaBoom";
  letter-spacing: 0.16em;
}
.keyword {
  font-family: "AboriginalSans";
  font-weight: bold;
  text-shadow: 2px 2px 5px white;
  color: black;
}

.keyword.flipped {
  animation-name: flipWord;
  animation-duration: 1s;
}

$positioning: clamp(40px, 5.5vmin, 65px);

.feedback_button_container {
  width: $positioning;
  height: $positioning;
  position: absolute;
  z-index: 999999999;
  bottom: 10px;
  right: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.feedback_button {
  width: $positioning;
  height: $positioning;
  border-radius: $positioning;
  background-color: rgba(43, 119, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: 3px white solid;
  filter: drop-shadow(0px 0px 3px black);
}

.feedback_text {
  display: none;
}

.feedback_button:hover > .feedback_text {
  width: clamp(80px, 21vmin, 180px);
  height: clamp(15px, 7vmin, 30px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(43, 119, 255, 1);
  border-radius: 4px;
  border: 3px white solid;
  filter: drop-shadow(2px 2px 2px black);
  position: absolute;
  top: -50px;
  right: 0px;
  color: white;
  font-weight: bold;
  font-size: clamp(8px, 1.5vmin, 15px);
}

@keyframes flipWord {
  0% {
    transform:
      rotateY(0deg) scale(1)
  }

  50% {
    transform:
      rotateY(180deg) scale(1.4)
  }

  100% {
    transform:
      rotateY(360deg) scale(1)
  }
}