@import '../../../index.scss';

.topnavigation_container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: clamp(40px, 14vw, 95px);
  display: flex;
  padding-left: 20px;

  @media (max-width: 768px) {
    padding-left: 10px;
  }

  align-items: center;
}

.topnavigation_backgroundimage {
  background-image: url($image_pre + 'Header1.png');
  background-size: contain;
  background-size: 102% 100%;
  background-repeat: no-repeat;
  height: clamp(55px, 10vw, 120px);
  position: absolute;
  top: -1px;
  bottom: 0px;
  left: -20px;
  right: 0px;
}

.topnavigation_contents {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  width: 100%;
}

.topnavigation_button_container {
  @extend .hovercursor;
  position: relative;
  margin-left: clamp(7px, 2vw, 20px);

  @media (max-width: 768px) {
    margin-left: clamp(2px, 1.25vw, 20px);
  }

  width: clamp(20px, 8vw, 48px);
  height: clamp(22.5px, 9.1vw, 54px);
  background-size: 100% 100%;
  align-self: flex-end;
  animation-name: bounce;
  animation-duration: 0.65s;
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  transform: translateY(-250px);
}

@for $i from 1 through 6 {
  .topnavigation_button_container:nth-child(#{$i}n) {
    animation-delay: #{$i * 0.09 + 0.5}s;
  }
}

@keyframes bounce {
  0% {
    transform: scale(.9, 1.1) translateY(-250px);
  }

  20% {
    transform: scale(1.05, .95) translateY(0);
  }

  35% {
    transform: scale(1, 1) translateY(-20px);
  }

  64% {
    transform: scale(1, 1) translateY(0);
  }

  100% {
    transform: scale(1, 1) translateY(0);
  }
}

.topnavigation_button_container_home {
  @extend .topnavigation_button_container;
  background-image: url($image_pre + 'Button_home_up.png');

  &:hover {
    background-image: url($image_pre + 'Button_home_down.png');
  }
}

.topnavigation_button_container_back {
  @extend .topnavigation_button_container;
  background-image: url($image_pre +'Button_back_up.png');

  &:hover {
    background-image: url($image_pre +'Button_back_down.png');
  }
}

.topnavigation_button_container_stories {
  @extend .topnavigation_button_container;
  background-image: url($image_pre +'Button_stories_up.png');

  &:hover {
    background-image: url($image_pre +'Button_stories_down.png');
  }
}

.topnavigation_button_container_menu {
  @extend .topnavigation_button_container;
  background-image: url($image_pre +'Button_menu_up.png');

  &:hover {
    background-image: url($image_pre +'Button_menu_down.png');
  }
}
.topnavigation_button_container_endless {
  @extend .topnavigation_button_container;
  background-image: url($image_pre +'Button_endless_up.png');

  &:hover {
    background-image: url($image_pre +'Button_endless_down.png');
  }
}

.topnavigation_button_container_timeline {
  @extend .topnavigation_button_container;
  background-image: url($image_pre +'Button_timeline_up.png');

  &:hover {
    background-image: url($image_pre +'Button_timeline_down.png');
  }
}

.topnavigation_button_container_credits {
  @extend .topnavigation_button_container;
  background-image: url($image_pre +'Button_credits_up.png');

  &:hover {
    background-image: url($image_pre +'Button_credits_down.png');
  }
}

.topnavigation_button_container_exit {
  @extend .topnavigation_button_container;
  position: absolute;
  right: clamp(11px, 3vw, 30px);
  top: 0;
  background-image: url($image_pre +'Button_exit_up.png');

  &:hover {
    background-image: url($image_pre +'Button_exit_down.png');
  }
}

.topnavigation_button_container:first-child {
  width: clamp(30px, 10vw, 65px);
  height: clamp(33.75px, 11.38vw, 73.125px);
}

.topnavigation_headercontainer {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  pointer-events: none;
  filter: drop-shadow(-1px 0px 0px white);
}

.topnavigation_header {
  font-size: clamp(15px, 5vmin, 40px);
  color: rgba(247, 149, 28, 1);
  position: relative;
  bottom: clamp(12px, 4.5vmin, 25px);
  z-index: 99999;
  filter: drop-shadow(2px 2px 0px black);
  white-space: nowrap;
  opacity: 0;
  animation-name: zoom-in;
  animation-duration: 0.25s;
  animation-fill-mode: forwards;
  animation-delay: 0.1s;
  animation-timing-function: ease-in;
}

.topnavigation_badgecontainer {
  // width: clamp(30px, 10vmin,100px);
  // height: clamp(30px, 10vmin,100px);
  display: flex;
  justify-content: center;
  position: absolute;
  top: 2vmin;
  right: 6.5vmin;
  opacity: 0;
  animation-name: zoom-in;
  animation-duration: 0.35s;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
  animation-timing-function: ease-in;
}

.topnavigation_badge {
  width: clamp(30px, 10vmin, 100px);
  height: clamp(30px, 10vmin, 100px);
  background-size: 100% 100%;
  position: relative;
  z-index: 99999;
  filter: drop-shadow(2px 2px 0px black);
}

@keyframes zoom-in {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }

  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}