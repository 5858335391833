@import '../../index.scss';

.loadingscreen_container {
  background-image: url($image_pre + 'welcome_background.png');
  background-size: 100% 100%;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.loadingscreen_contentContainer {
  transform: translateY(-150vh);
  animation-name: slide-down;
  animation-duration: 1s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  background-image: url($image_pre + 'loadingscreen_contentcontainer.png');
  background-size: 100% 100%;
  height: 100vmin;
  width: 100vmin;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loadingscreen_content {
  font-size: min(10vmin, 50px);
  letter-spacing: 0.075em;
  filter: drop-shadow(1px 1px 2px white);
}

.loadingscreen_contentSmall {
  font-size: min(3vmin, 30px);
  line-height: .5em;
  letter-spacing: 0.065em;
  filter: drop-shadow(1px 1px 2px white);
}

@keyframes slide-down {
  0% {
    transform: translateY(-150vh);
  }

  100% {
    transform: translateY(0);
  }
}