.itemFallGame-fullscreen {
  font-size: 20px;
  background:
    linear-gradient(rgba(131, 58, 180, .7) 0%,
      rgba(253, 29, 29, .7) 50%,
      rgba(252, 176, 69, .7) 100%);
  overscroll-behavior: contain;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.itemFallGame-keywordBox {
  line-height: 1em;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 75px;
  min-width: 200px;
  height: 9.38vw;
  width: 25vw;
  max-height: 150px;
  max-width: 400px;
  padding: 10px;
  font-size: 2.7vmin;
}

.IFG-EDLS-buttonContainer {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.IFG-EDLS-menuButton {
  min-height: 40px;
  min-width: 100px;
  height: 6.12vw;
  width: 14.28vw;
  max-height: 100px;
  max-width: 280px;
  background-color: rgba(49, 181, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  color: white;
  font-weight: bold;
  margin: 20px;
  box-shadow: 2px 2px 2px black;
}

.IFG-EDLS-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}

.IFG-statScreenContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.IFG-greyBox {
  background-color: rgba(0, 0, 0, .3);
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0px;
  left: 0px;
}

.IFG-BSB-statContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}