@import '../../index.scss';

// .Dict_container {
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   background-image: url($image_pre + 'Game_Selection_Background.png');
//   background-size: 100% 100%;
//   overflow-y: auto;
// }

.Dict_contents {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-top: 100px;
  padding-bottom: 75px;
}

.Dict-fullscreen-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url($image_pre + 'Game_Selection_Background.png');
  background-size: 100% 100%;
  overflow-y: auto;
  height: 100%;
  min-height: 100vh;
  width: 100vw;
}

.Dict-content-container {
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-top: clamp(70px, 15vmin, 150px);
  padding-bottom: 75px;
}
.Dict-inner-content-container {
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  
  justify-content: space-evenly;
  flex-wrap: wrap;
  // padding-bottom: 75px;
}

.Dict-content-left {
  display: flex;
  flex: 2;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.Dict-content-left-inner {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  position: sticky;
  top: 10px;
}

.Dict-content-right {
  display: flex;
  flex: 5;
  justify-content: center;
  flex-wrap: wrap;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 10px;
  margin: 10px;
  border-radius: 8px;
  width: 100%;
}

.Dict-content-right-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.Dict-category {
  height: 30px;
  border-radius: 20px;
  background:
    linear-gradient(rgba(74, 135, 195, 1.0) 0%,
      rgba(74, 135, 195, 1.0) 60%,
      rgba(57, 92, 167, 1.0) 100%);
  margin: 4px;
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(1px 1px 3px black);
}

.Dict-category-selected {
  height: 30px;
  border-radius: 20px;
  background:
    linear-gradient(rgba(57, 162, 74, 1.0) 0%,
      rgba(23, 130, 35, 1.0) 100%);
  margin: 4px;
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(1px 1px 3px black);
}

.Dict-category-title {
  font-size: clamp(15px, 2vmin, 25px);
  color: white;
}

.Dict-right-title {
  color: white;
  font-size: 28px;
  line-height: 28px;
  margin-bottom: 8px;
}

.Dict-word-expanded-container {
  border-radius: 10px;
  background-color: white;
  margin-bottom: 5px;
  width: 100%;
  display: inline-block;
}

.Dict-word-button {
  border-radius: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: rgba(57, 92, 167, 1.0);
  border: 1px black solid;
}

.Dict-word-button-title-container {
  padding: 3px 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.Dict-word-button-title {
  color: white;
}

.Dict-word-button-subtitle {
  margin-left: 10px;
  color: rgba(127, 212, 255, 1.0);
}

.Dict-word-button-expanded-content {
  padding: 4px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Dict-word-button-expanded-image {
  width: 200px;
  height: 200px;
  object-fit: contain;
}


.search-bar-container {
  width: 90%;
  height: 45px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  filter: drop-shadow(2px 2px 3px black);
}
.search-bar-icon-container {
  background-color: white;
  height: 45px;
  width: 45px;
  border-radius: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  padding: 5px 7px;
}
.search-bar-input {
  border-width: 0;
  width: 100%;
  font-size: 35px;
  background-color: white;
  height: 45px;
  padding: 5px 20px;
  border-radius: 30px;
  display: flex;
  align-items: center;
}