@import '../../index.scss';

.EM-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-top: 150px;
  padding-bottom: 75px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.EMRTS-box {
  background-image: url($image_pre + 'Game_Selection_Display2x.png');
  background-size: 100% 100%;
}

.EMCS-easyGradientColor {
  background:
    linear-gradient(rgba(100, 170, 49, 1),
      rgba(40, 100, 0, 1))
}

.EMCS-hardGradientColor {
  background:
    linear-gradient(rgba(255, 40, 40, 1),
      rgba(180, 0, 0, 1))
}

.easyColor {
  background-color: rgba(100, 170, 49, 1);
}

.mediumColor {
  background-color: rgba(255, 146, 32, 1);
}

.hardColor {
  background-color: rgba(253, 29, 29, 1);
}

.EM-RTS-overlayScreen {
  position: fixed;
  z-index: 999999999999;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, .7);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: black;
  line-height: 1.3em;
  font-family: BadaBoom;
}

.EM-RTS-popupWindow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px;
  height: clamp(250px, 45vmin, 600px);
  width: clamp(400px, 75vmin, 1000px);
  font-size: max(2.46vw, 13px);
  position: relative;
}

.EMRTS-contentcontainer {
  position: absolute;
  top: 15%;
  left: 10%;
  right: 15%;
  bottom: 12%;
}

.EM-RTS-button {
  font-size: max(1.67vw, 9px);
  min-height: 40px;
  min-width: 100px;
  height: 6.12vw;
  width: 14.29vw;
  max-width: 280px;
  max-height: 100px;
  background-color: rgba(49, 181, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  color: white;
  font-weight: bold;
  margin: 20px;
  box-shadow: 2px 2px 2px black;
}
.EM-RTS-button-reselect {
  background-image: url($image_pre + 'Game_Selection_Display2x.png');
  background-size: 100% 100%;
}
.EM-RTS-button-continue {
  background-image: url($image_pre + 'Game_Selection_Display2x.png');
  background-size: 100% 100%;
}

.EM-topBanner {
  position: absolute;
  top: 30px;
  right: 50px;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: clamp(350px, 50vmin, 700px);
  height: clamp(90px, 10vmin, 200px);
  background-image: url($image_pre + 'Modal_Message_Display.png');
  background-size: 100% 100%;
}
.EM-topBanner-inner {
  font-family: BadaBoom;
  font-size: clamp(20px, 4vmin, 40px);
  filter: drop-shadow(1px 1px 2px black);
  letter-spacing: 3px;
  color: white;
  width: 70%;
  position: absolute;
  top: 32%;
  left: 3vmin;
}