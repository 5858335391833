.fillInTheBlank-aboriginal {
  font-family: "Aboriginal Sans";
}

.FITB-card {
  background-color: rgba(50, 50, 255, .4);
  border-radius: 10px;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  height: clamp(15px, 2.5vmin, 27px);
  padding: clamp(15px, 3vmin, 27px);
  padding-bottom: clamp(15px, 2vmin, 27px);
}

.FITB-emptyCard {
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: clamp(15px, 2.5vmin, 27px);
  padding-top: clamp(15px, 3vmin, 27px);
  padding-bottom: clamp(15px, 2vmin, 27px);
  min-width: 100px;
}

.FITB-plainText {
  font-size: clamp(18px, 3.33vmin, 35px);
}

.FITB-cardContents {
  font-size: clamp(18px, 3.33vmin, 35px);
  color: black;
}

.FITB-container {
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.FITB-placemat {
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 80%;
  padding: clamp(9px, 1.67vmin, 18px);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, .5);
}

.FITB-cardOptionsContainer {
  height: 25vh;
  width: 90vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.FITB-plusMinusContainer {
  position: absolute;
  top: 60vh;
  left: 50vw;
  z-index: 99999;
  height: 2px;
  width: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.FITB-BSB-container {
  display: flex;
  justify-content: center;
}

.FITB-fullScreen {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, .2);
  position: absolute;
  top: 0px;
  left: 0px;
}