@import "../../index.scss";

.credits-full-screen {
  background-image: url($image_pre + 'welcome_background.png');
  background-size: 100% 100%;
}

.credits-contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
}

.credits-creditContainer {
  background-color: rgba(0, 0, 0, .7);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px;
  height: 33.33vh;
  width: 83.33vw;
  margin-top: 50px;
}

.credits-logosContainer {
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100vw;
}

.credits-headerText {
  color: white;
  line-height: 2em;
  font-size: max(min(2.22vmin, 30px), 12px);
}

.credits-logoAndSite {
  height: max(min(20.42vmin, 350px), 155px);
  width: max(min(28.57vmin, 450px), 200px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.credits-link {
  font-size: max(min(1.43vmin, 23px), 12px);
  color: black;
  text-decoration: underline;
}

.credits-logo {
  width: max(40vmin, 240px);
}