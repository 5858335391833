@import '../../../index.scss';

.indivgame_container {
  transform: translateX(-100vw);
  position: relative;
  width: clamp(250px, 32vw, 400px);
  height: clamp(100px, 12.8vw, 160px);
  margin: clamp(10px, 3.5vmin, 35px) 1vmin;
  display: flex;
  align-items: center;
  animation-name: slide-right;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

.unitsubmenu_container {
  @extend .indivgame_container;
  width: clamp(162.5px, 23vw, 300px);
  height: clamp(65px, 9.6vw, 120px);
  margin: clamp(10px, 3vmin, 30px) 0.5vmin;
}

@for $i from 1 through 11 {
  .indivgame_container:nth-child(#{$i}n) {
    animation-delay: #{$i * 0.4}s;
  }
}

.indivgame_innercontainer {
  position: relative;
  width: clamp(250px, 32vw, 400px);
  height: clamp(100px, 12.8vw, 160px);
  margin: clamp(10px, 3.5vmin, 35px) 1vmin;
  display: flex;
  align-items: center;
}

.unitsubmenu_innercontainer {
  @extend .indivgame_innercontainer;
  width: clamp(162.5px, 24vw, 300px);
  height: clamp(65px, 9.6vw, 120px);
  margin: clamp(10px, 3vmin, 30px) 0.5vmin;
}

.indivgame_innercontainer:hover,
.unitsubmenu_innercontainer:hover {
  animation-name: my-bounce;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

.indivgame_badge {
  position: relative;
  display: flex;
  z-index: 1;
  flex-direction: column;
  align-items: center;
  background-image: url($image_pre + 'Game_Type_Header2x.png');
  background-size: 100% 100%;
  height: clamp(100px, 12.8vw, 160px);
  width: clamp(88.5px, 11.32vw, 141.5px);
}

.unitsubmenu_badge {
  @extend .indivgame_badge;
  height: clamp(75px, 9.6vw, 120px);
  width: clamp(66.4px, 8.49vw, 106.1px);
}

.indivgame_gameimg {
  position: absolute;
  z-index: 0;
  bottom: 5%;
  // background-image: url($image_pre + 'Game_Type_Bubble_Pop2x.png');
  background-size: 100% 100%;
  height: clamp(100px, 12.8vw, 160px);
  width: clamp(88.5px, 11.32vw, 141.5px);
  filter: drop-shadow(3px 2px 3px rgba(0, 0, 0, 0.5));
}

.unitsubmenu_gameimg {
  @extend .indivgame_gameimg;
  height: clamp(75px, 9.6vw, 120px);
  width: clamp(66.4px, 8.49vw, 106.1px);
}

.indivgame_badgebanner {
  position: absolute;
  bottom: 21%;
  background-image: url($image_pre + 'Game_Type_Stars_Base2x.png');
  background-size: 100% 100%;
  width: clamp(100px, 11.5vw, 150px);
  height: clamp(29.8px, 3.42vw, 44.7px);
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  :first-child {
    margin-left: 2vmin;
  }

  :last-child {
    margin-right: 2vmin;
  }
}

.unitsubmenu_badgebanner {
  @extend .indivgame_badgebanner;
  bottom: 18%;
  width: clamp(75px, 8.5vw, 113px);
  height: clamp(22.5px, 2.56vw, 33px);

  :first-child {
    margin-left: 1vmin;
  }

  :last-child {
    margin-right: 1vmin;
  }
}

.indivgame_Star {
  background-image: url($image_pre + 'Game_Type_Star2x.png');
  background-size: 100% 100%;
  height: clamp(20px, 2.29vw, 30px);
  width: clamp(20px, 2.29vw, 30px);
  margin-bottom: 0.6vmin;
}

.indivgame_Star_Base {
  @extend .indivgame_Star;
  background-image: url($image_pre + 'Game_Type_Star_Base2x.png');
}

.unitsubmenu_Star {
  @extend .indivgame_Star;
  height: clamp(15px, 1.6vw, 25px);
  width: clamp(15px, 1.6vw, 25px);
  margin-bottom: 0.3vmin;
}

.unitsubmenu_Star_Base {
  @extend .unitsubmenu_Star;
  background-image: url($image_pre + 'Game_Type_Star_Base2x.png');
}

.indivgame_titlecontainer {
  position: absolute;
  right: 0;
  // z-index: 300;
  background-image: url($image_pre + 'Game_Type_Display2x.png');
  background-size: 100% 100%;
  height: clamp(50px, 6.4vw, 80px);
  width: clamp(175px, 22.4vw, 280px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.unitsubmenu_titlecontainer {
  @extend .indivgame_titlecontainer;
  height: clamp(37px, 4.8vw, 60px);
  width: clamp(120px, 15vw, 200px);
}

.indivgame_title {
  font-size: clamp(20px, 2.75vmin, 45px);
  color: black;
}

@media (max-width: 1000px) and (min-width: 625px) {
  .indivgame_title {
    font-size: clamp(20px, 3vmin, 45px);
    padding-left: 1.5vmin;
  }
}

@media (max-width: 624px) {
  .indivgame_title {
    padding-left: 2vmin;
  }
}

.unitsubmenu_title {
  @extend .indivgame_title;
  text-align: center;
  font-size: clamp(10px, 2vmin, 34px);
}

@media (max-width: 1000px) and (min-width: 625px) {
  .unitsubmenu_title {
    font-size: clamp(11px, 1.2vmin, 28px);
    padding-left: .75vmin;
  }
}

.indivgame_progressbarcontainer {
  position: absolute;
  bottom: 0;
  z-index: 1;
  left: clamp(0px, 0.5vw, 10px);
}

.unitsubmenu_progressbarcontainer {
  position: absolute;
  top: 82%;
  z-index: 1;
  // left: clamp(0px, 1vw, 3px);
  left: 0px;
}

@media (max-width: 740px) {
  .unitsubmenu_title {
    padding-left: 3vmin;
  }

  .unitsubmenu_progressbarcontainer {
    left: auto;
  }
}

/* Animations */

@keyframes slide-right {
  0% {
    transform: translateX(-100vw);
  }

  100% {
    transform: translateX(0vw);
  }
}

// @keyframes bounce2 {
//   0% {
//     transform: translateY(0vh);
//   } 25% {
//     transform: translateY(-1.5vh);
//   } 75% {
//     transform: translateY(1.5vh);
//   } 100% {
//     transform: translateY(0vh);
//   }
// }

@keyframes my-bounce {
  0% {
    transform: scale(1, 1) translateY(0vh);
  }

  30% {
    transform: scale(1, 1) translateY(0vh);
  }

  45% {
    transform: scale(0.85, 1.2) translateY(-9vh);
  }

  60% {
    transform: scale(1.2, 0.7) translateY(2vh);
    // } 75% {
    //   transform: scale(1, 1) translateY(0vh);
  }

  80% {
    transform: scale(0.95, 1.05) translateY(-1vh);
  }

  90% {
    transform: scale(1.05, 0.85) translateY(1vh);
  }

  98% {
    transform: scale(0.98, 1.02) translateY(-0.2vh);
  }

  100% {
    transform: scale(1, 1) translateY(0vh);
  }
}