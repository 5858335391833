@import '../../../index.scss';

.EMCS-easyGradientColor {
  background:
    linear-gradient(rgba(100, 170, 49, 1),
      rgba(40, 100, 0, 1))
}

.EMCS-hardGradientColor {
  background:
    linear-gradient(rgba(255, 40, 40, 1),
      rgba(180, 0, 0, 1))
}

.EM-CS-fullscreen {
  background-image: url($image_pre + 'Game_Selection_Background.png');
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.EM-CS-checkmarkDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  position: absolute;
  height: 100%;
  width: 100%;
}

.EM-CS-BC-container {
  /* background-color: green; */
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  /* font-size: 18px; */
  font-size: clamp(10px, 1.5vw, 18px);
  line-height: 1.2em;
  width: clamp(150px, 20vmin, 400px);
  height: clamp(225px, 25vmin, 600px);
  background-image: url($image_pre + 'SubUnitMenu_TileImg.png');
  background-size: 100% 100%;
  position: relative;
}
.EM-BC-titlecontainer {
  height: clamp(35px, 3vmin, 40px);
  width: clamp(150px, 18vmin, 240px);
  background-image: url($image_pre + 'SubUnitMenu_TileHeaderImg.png');
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.EM-BC-title {
  font-size: clamp(18px, 2vmin, 26px);
  font-family: AbSans;
  font-weight: bold;
  letter-spacing: 2px;
  filter: drop-shadow(1px 1px 2px black);
}

.EM-CS-easyCirc {
  margin-right: 5px;
  height: 25px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(100, 170, 49, 1);
  padding: 0px 10px;
}

.EM-CS-easyCircFull {
  border-radius: 12.5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(100, 170, 49, 1);
  padding: 0px 7px 2px 7px;
  margin-bottom: 5px;
}

.EM-CS-mediumCirc {
  margin-right: 5px;
  height: 25px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 146, 32, 1);
  padding: 0px 10px;
}

.EM-CS-mediumCircFull {
  border-radius: 12.5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 146, 32, 1);
  padding: 0px 7px 2px 7px;
  margin-bottom: 5px;
}

.EM-CS-hardCirc {
  margin-right: 5px;
  height: 25px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(253, 29, 29, 1);
  padding: 0px 10px;
}

.EM-CS-hardCircFull {
  border-radius: 12.5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(253, 29, 29, 1);
  padding: 0px 7px 2px 7px;
  margin-bottom: 5px;
}

.EM-CS-circContainer {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.EM-CS-singleButton {
  margin-bottom: clamp(12px, 4vmin, 25px);
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  // filter: grayscale(80%);
}

.EM-CS-innerContainer {
  margin: 6px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 60%;
  height: 70%;
}

.EM-CS-checkContainer {
  position: absolute;
  font-size: 40px;
  background-color: rgba(40, 175, 70, .6);
  border: 4px solid rgba(0, 151, 0, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  width: 100%;
  height: 100%;
}

.EM-CS-icon {
  color: rgba(255, 255, 255, .7)
}

.EM-CS-content {
  background-color: rgba(100, 100, 100, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 10px 0px;
  border-radius: 30px;
  font-size: 18px;
  margin: 0px 8px 10px 8px;
  flex-grow: 1;
}

.EM-CS-title {
  font-size: clamp(14px, 3.33vw, 26px);
  margin-bottom: 10px;
}

.EM-CS-categoryContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.2em;
}

.EM-CS-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 25px;
}

.EM-CS-topBannerSub {
  font-size: clamp(9px, 1.8vmin, 20px);
}

.EM-CS-categoryDisplay {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: clamp(350px, 66.67vw, 500px);
  font-size: clamp(9px, 1.67vw, 25px);
}

.EM-CS-button-goback {
  margin: 5px 20px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: clamp(200px, 20vw, 350px);
  // border-radius: 10px;
  filter: drop-shadow(2px 3px black);
  background-image: url($image_pre + 'modal_red_button.png');
  background-size: 100% 100%;
  font-family: BadaBoom;
  letter-spacing: 2px;
  color: white;
}
.EM-CS-button-continue {
  @extend .EM-CS-button-goback;
  background-image: url($image_pre + 'modal_green_button.png');
}
.EM-CS-button-selectcats {
  @extend .EM-CS-button-goback;
  font-weight: 500;
  padding: clamp(1px, 1vmin, 15px) 0;
  width: clamp(200px, 25vw, 500px);
}

.EM-CS-fixedButtonOne {
  position: fixed;
  top: 55px;
  left: 15px;
  width: 150px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 2px 2px 3px black;
}

.EM-CS-fixedButtonTwo {
  position: fixed;
  top: 55px;
  right: 15px;
  width: 150px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 2px 2px 3px black;
}

.EM-CS-menuButton {
  min-height: 40px;
  min-width: 100px;
  height: 6.12vw;
  width: 14.29vw;
  max-width: 280px;
  max-height: 100px;
  background-color: rgba(49, 181, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  color: white;
  font-weight: bold;
  font-size: clamp(9px, 1.67vw, 25px);
  margin: 20px;
  box-shadow: 2px 2px 2px black;
}

.EM-CS-buttonContainer {
  color: white;
  font-size: 24px;
  position: absolute;
  z-index: 999999999;
  bottom: 0;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100vw;
}

.EM-CS-RTS-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.EM-CS-RTS-innerContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}
.EM-CS-RTS-titletext {
  font-size: clamp(22px, 5vmin, 40px);
  line-height: 1.2;
  text-align: center;
}
.EM-CS-RTS-catStringContainer {
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 3px;
  line-height: 1.5em;
  background-color: rgba(49, 181, 255, 1);
  border-radius: 200px;
  color: black;
  letter-spacing: 2px;
  filter: drop-shadow(2px 2px 1px black);
  font-size: clamp(18px, 4vmin, 32px);
  margin: 8px 2px;
}

.EM-CS-RTS-buttonContainer {
  display: flex;
  justify-content: center;
}

.EM-CS-mainContainer {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 20px;
}