@import '../../index.scss';

.choose_container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  background-image: url($image_pre + 'welcome_background.png');
  background-size: 100% 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.choose_button_container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.choose_button, .choose_button_right {
  display: flex;
  justify-content: center;
  align-items: center;
  height: clamp(40px, 15vmin, 160px);
  width: clamp(120px, 45vmin, 480px);
  position: relative;
}

.choose_button::before, .choose_button_right::before {
  background-image: url($image_pre + 'Category_Selection_Play_Button_Up.png');
  background-size: 100% 100%;
  content: "";
  position: absolute;
  inset: 0;
}
.choose_button:hover::before, .choose_button_right:hover::before {
  animation: fade-out-before 0.3s ease-in-out 0s 1 normal forwards;
}

.choose_button:hover {
  animation: fade-out 0.3s ease-in-out 0s 1 normal forwards;
}
.choose_button_right:hover {
  animation: fade-out-right 0.3s ease-in-out 0s 1 normal forwards;
}

@keyframes fade-out {
  0% {
    background-color: rgba(0, 0, 0, 0);
  } 100% {
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    box-shadow: 5px 0px 5px rgba(0, 0, 0, 0.8);
  }
}
@keyframes fade-out-right {
  0% {
    background-color: rgba(0, 0, 0, 0);
  } 100% {
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    box-shadow: -5px 0px 5px rgba(0, 0, 0, 0.8);
  }
}
@keyframes fade-out-before {
  0% {
    opacity: 1;
  } 100% {
    opacity: 0;
  }
}

.choose_button_text {
  color: white;
  font-family: 'BadaBoom';
  font-size: clamp(20px, 7.5vmin, 120px);
  letter-spacing: clamp(1px, 0.5vmin, 9px);
  filter: drop-shadow(2px 2px 0 black);
}