@import '../../../index.scss';

.cleardatapopup_fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999999999999999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.cleardatapopup_modal {
  background-image: url($image_pre + 'SubUnitMenu_TileImg.png');
  background-size: 100% 100%;
  height: clamp(300px, 70vmin, 800px);
  width: clamp(300px, 70vmin, 800px);
  position: relative;
}

.cleardatapopup_modalcontentscontainer {
  position: absolute;
  top: 25%;
  right: 18%;
  bottom: 14%;
  left: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.cleardatapopup_modalcontentscontainer h1 {
  text-align: center;
}

.cleardatapopup_buttoncontainer {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.cleardatapopup_button {
  background-size: 100% 100%;
  height: clamp(20px, 6vmin, 50px);
  width: clamp(70px, 21vmin, 175px);
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(1px 1px 0px black);
}

.cleardatapopup_buttontext {
  font-size: clamp(12px, 3vmin, 25px);
  letter-spacing: 2px;
  color: white;
  filter: drop-shadow(2px 1px 0px black);
}

.cleardatapopup_buttonyes {
  @extend .cleardatapopup_button;
  background-image: url($image_pre + 'modal_green_button.png');
}

.cleardatapopup_buttonno {
  @extend .cleardatapopup_button;
  background-image: url($image_pre + 'modal_red_button.png');
}