@import '../../../index.scss';

.gamemenu_container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url($image_pre + 'Game_Selection_Background.png');
  background-size: 100% 100%;
  overflow-y: auto;
}

.gamemenu_contents {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-top: 100px;
  padding-bottom: 75px;
}