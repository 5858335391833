@import '../../index.scss';
.timeline-fullscreen-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url($image_pre + 'Game_Selection_Background.png');
  background-size: 100% 100%;
  overflow-y: auto;
  height: 100%;
  min-height: 100vh;
  width: 100vw;
}

.timeline-loading-screen {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.timeline-loading-container {
  background-color: rgba(28,140,203,1);
  width: clamp(200px, 30vmin, 500px);
  height: clamp(80px, 12vmin, 200px);
  border: white 4px solid;
  border-radius: 10px;;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeline-loading-text {
color: white;
font-size: 40px;
}

.timeline-bsb-stat {
  display: flex;
  justify-content: center;
}

.timeline-plus-minus-container {
  position: absolute;
  top: 40vh;
  left: 50vw;
  z-index: 99999;
  height: 2px;
  width: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-disappearing-container {
  background-color: white;
  width: 100%;
  position: absolute;
  top: 0px;
  border-radius: 9px 9px 0px 0px;
}

.card-year-text {
  display: flex;
  width: 100%;
  color: black;
  justify-content: center;
}

.card-card-text {
  color: black;
  line-height: 1.3;
  text-align: center;
  position: absolute;
  bottom: 0;
  padding: 5px;
}

.table-table {
  padding: 8px;
  display: flex;
  flex-grow: 1;
  justify-content: space-evenly;
  align-items: center;
}

.start-game-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.game-ends-title-container {
  filter: drop-shadow(0px 6px 6px black);
  background-image: url($image_pre + 'Category_Selection_Play_Button_Up.png');
  background-size: 100% 100%;
  margin-bottom: 10vmin;
}
.game-ends-title {
  color: white;
  font-size: clamp(40px, 10vmin, 120px);
  filter: drop-shadow(0px 2px 2px black);
  font-weight: bold;
  padding: 3vmin 10vmin 5vmin;
  text-align: center;
}

.start-game-button {
  background-image: url($image_pre + 'modal_green_button.png');
  background-size: 100% 100%;
  height: clamp(60px, 18vmin, 150px);
  width: clamp(210px, 63vmin, 550px);
  // background-color: green;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}

.start-game-button-text {
  color: white;
  font-size: clamp(30px, 8vmin, 70px);
}