@import '../../../index.scss';

.bottomnavigation_container {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  height: clamp(15px, 10vw, 75px);
}

.bottomnavigation_backgroundimage {
  background-image: url($image_pre + 'Footer2.png');
  background-size: contain;
  background-size: 102% 100%;
  background-repeat: no-repeat;
  height: clamp(20px, 10vw, 80px);
  position: absolute;
  top: 0px;
  bottom: -10px;
  left: -10px;
  right: 0px;
}
.bottomnavigation_backgroundimage_welcome {
  @extend .bottomnavigation_backgroundimage;
  top: -35px;
  // bottom: 10px;
}

.bottomnavigation_message {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 21px;
  position: absolute;
  width: 100%;
  z-index: -1;
  height: 35px;
  top: 40px;
  color: white;
  animation: flicker 3000ms ease-in-out infinite;
}

@keyframes flicker {
  0% {
    color: white;
  } 50% {
    color: rgba(150,150,150,1);
  } 100% {
    color: white;
  }
}

.bottomnavigation_contents {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  width: 100%;
}

.bottomnavigation_welcomecontents {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.bottomnavigation_chooseunitcontents {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 50;
  bottom: clamp(12px, 3vmin, 25px);
  align-items: center;
}

$badge_dim: clamp(45px, 9vmin, 60px);
$progressbar_margin: clamp(5px, 3vmin, 20px);
$progressbar_width: clamp(100px, 60vw, 220px);

.bottomnavigation_chooseunitbadge {
  width: $badge_dim;
  height: $badge_dim;
  background-size: 100% 100%;
}

.bottomnavigation_chooseunitbadgecover {
  background-image: url($image_pre + 'Level_Button_Cover2x.png');
  width: $badge_dim;
  height: $badge_dim;
  background-size: 103% 103%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottomnavigation_chooseunitbadgelock {
  background-image: url($image_pre + 'Level_Lock2x.png');
  width: calc($badge_dim / 1.5);
  height: calc($badge_dim / 1.5);
  background-size: 100% 100%;
}